





































































import TeamRosterViewDetails from "@/popupcomponents/teamrosterviewdetails/TeamRosterViewDetails";
export default TeamRosterViewDetails;
